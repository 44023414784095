<template>
    <div class="ticket-checker-form">
        <h3 v-if="!showRes">Check Winning Ticket</h3>
        <p v-if="!showRes">Enter the 23 or 35 digit serial number from your ticket below. Do not include dashes or spaces.</p>

        <!-- FORM -->
        <form data-abide novalidate data-validate-on-blur="true" id="ticketCheckerForm" v-if="!showRes">
            <input type="number" minlength="23" maxlength="35" placeholder="Enter ticket number or promotion code"
                v-model="inputValue" required pattern="^(?:\d{23}|\d{35})$" id="inputTicketChecker">

            <span class="form-error error regError" data-form-error-on="required"
                data-form-error-for="inputTicketChecker">Please provide a ticket number.</span>
            <span class="form-error error regError" data-form-error-on="pattern"
                data-form-error-for="inputTicketChecker">Please check ticket format and try again.</span>

            <input class="button secondary" type="submit" value="Check Ticket" v-if="!isValid">
            <input class="button secondary" value="Clear Field" @click="clearInput" v-if="isValid">
        </form>

        <!-- LOADING -->
        <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" v-if="isLoading" />

        <!-- Non-Winning Ticket -->
        <div class="error ticketFormatError" v-if="isValid && resArray.isWinningTicket === false">
            <p>resArray.scanResultMessage</p>
        </div>
        
        <!-- ERRORS -->
        <div class="error ticketFormatError" v-if="isValid">
            <p>{{ this.errorMessage }}</p>
        </div>

        <!-- RESULTS -->
        <!-- NOTE old area that is no longer active -->
        <!-- <div class="ticket-entry-results" v-if="showRes && !isLoading && resArray.isWinningTicket === false"> -->
            <!-- <div class="grid-x">
                <div class="column small-3 medium-3 large-3 game-logo">
                    <img :src="resArray.gameImageURL" v-if="resArray.gameImageURL" />
                    <small v-if="!resArray.gameImageURL">Fallback img</small>
                </div>
                <div class="column small-6 medium-6 large-7 game-detail">
                    <p v-if="resArray.gameID != ''">{{ resArray.gameID }}</p>
                    <p v-if="resArray.scanResultMessage != ''">{{ resArray.scanResultMessage }}</p>
                </div>
                <div class="column small-3 medium-3 large-2 game-point">
                    <p>{{ resArray.ticketWinAmount }} points</p>
                </div>
            </div> -->
            <!-- <div class="grid-x">
                <div class="column small-12 medium-12 large-12 game-next" v-if="resArray.isWinningTicket">
                    <button @click="next" class="button">Next</button>
                </div>
            </div> -->

            <!-- <div class="ticket-entered grid-x grid-margin-x">
                <div class="cell small-4">
                    <img :src="resArray.gameImageURL" v-if="resArray.gameImageURL" />
                    <small v-if="!resArray.gameImageURL">Fallback img</small>
                </div>
                <div class="cell small-4" v-if="!resArray.gameImageURL">
                    <img :src="resArray.gameImageURL" v-if="isLogoExist" />
                    <p>Fallback img</p>
                </div>
                <div class="cell small-6">
                    <p>{{ resArray.ticketCode }}</p>
                    <p>{{ resArray.scanResultMessage }}</p>
                </div>
                <div class="cell small-2">
                    <p>{{ resArray.ticketWinAmount }} points</p>
                </div>
                <button @click="next" class="button">Next</button>
            </div> -->
        <!-- </div> -->
    </div>
</template>

<script>
import $ from 'jquery';
import { HollowDotsSpinner } from 'epic-spinners'

export default {
    components: {
        HollowDotsSpinner
    },
    data: function () {
        return {
            showClaimForm: false,
            inputValue: '',
            isLoading: false,
            isWinningTicket: false,
            showRes: false,
            isValid: false,
            isLogoExist: false,
            resArray: [],
            isTest: false,
            testData: {
                statusCode:	200,
                message:	"",
                error:	null,
                data: {
                    scanResultTitle:	"",
                    scanResultMessage:	"This is a winning ticket and is not eligible \r\n for points.",
                    ticketWinAmount:	100,
                    gameImageURL:	"/getattachment/51a7a00a-b27f-401d-9e5d-539ba0b9db64/1431_GameLogo-65.png",
                    gameID: 827,
                    subGameID: "0",
                    ticketCost: 50,
                    secondChancePromotionIdList: [],
                    submittable: false,
                    isWinningTicket: true,
                    isValid: true,
                    gameName: "50th Anniversary",
                    gameType: 2,
                    ticketStatus: 0,
                    ticketGrossAmount: 100,
                    ticketNetAmonut: 0,
                    ticketTaxAmount: 0,
                    ticketBonusAmount: 0,
                    ticketTransactionTimeStamp: 1715086613,
                    ticketPTB_flag: false,
                    ticketAuth_number: 0,
                    submittableForPoints: false,
                    drawDate: "0001-01-01T00:00:00"
                }
            }
        }
    },
    mounted: function () {
        let _this = this;
        $('#ticketCheckerForm').foundation();

        $('#ticketCheckerForm').on("formvalid.zf.abide", function () {
            _this.isValid = false;
            _this.isLoading = true;
            _this.getToken();
        }).on("submit", function (ev) {
            ev.preventDefault();
            _this.$emit("getTicketChecker", { showClaimForm: false});
        });
    },
    methods: {
        clearInput() {
            this.isValid = false;
            this.errorMessage = '';
            this.inputValue = '';
        },
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });

        },
        fetchData(token) {
            var apiToken = token;
            var urlProtocol = this.getProtocol();
            var value = this.inputValue;
            var user = $cookies.get('TokenInfo');
            // console.log("Claim Form userId");
            // console.log(user.User.UserId);

            return fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/TicketChecker/CheckTicket/" + value + "/" + user.User.UserId, {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {

                    let res = json.data;

                    // use testing data for local testing
                    let thisSite = window.location.hostname;
                    if (thisSite == 'mtllc.lottery.site') {
                        this.isTest = true;
                        res = this.testData.data;
                        // console.log('||| is local dev |||');
                    }
                    // console.log(res);

                    this.isLoading = false;

                    if (res.isValid) {
                        this.isValid = false;
                        this.resArray = res
                        this.showRes = true;
                        res.isWinningTicket === true ? this.next() : false;
                    } else {
                        this.isValid = true;
                        this.showRes = false;
                        this.errorMessage = res.scanResultMessage;
                    }

                    // console.log(this.resArray, this.isValid, this.errorMessage);

                    //var results = json.data;
                    // TESTING DATA:
                    // var results = {
                    //     WinAmount: '15.00',
                    //     AllowClaim: true,
                    //     GameName: "Battleship",
                    //     DrawDate: "9/20/2022",
                    //     ClaimStatus: 0,
                    //     PTB: false
                    // }


                    // if (json.statusCode === 500) {
                    //     this.isValid = true;
                    //     this.errorMessage = json.error.message
                    // }

                    // if (results) {
                    //     this.resArray = results;
                    // }
                    // this.showRes = true;
                })
                .catch(error => {
                    console.log(error)
                });
        },
        next() {
            // GET IF AllowClaim False or True To Show Next Button
            this.$emit("getTicketChecker", { ticketChecker: this.resArray, TicketNumber: this.inputValue, showClaimForm: true });
        }

    },
    computed: {

    },
};
</script>

<style lang="scss" scoped>
.ticket-checker-form {
    padding: 40px 20px;
    background: #f1efee;
    margin-top: 70px;

    .hollow-dots-spinner {
        margin: 40px auto 0 auto;
    }

    .error-ticket {
        p {
            color: #F55220;
            font-weight: bold;
        }
    }

    .button {
        margin-bottom: 0;
    }

    .ticketFormatError {
        margin: 1.5rem 0;
    }

    .ticket-entry-results {
        margin: 2rem 0 0;
        padding: 2rem 0 0;
        border-top: 1px solid #d3d3d3;

        .game-logo {
            img {
                max-width: 80%;
            }
        }
        .game-detail, .game-point{
            padding-left: 2rem;
        }
        .game-point{
            text-align: right;
        }
        .game-next{
            padding-top: 2rem;
            button{
                margin: 0 0 0 auto;
            }
        }
    }
}
</style>